/* Основные стили для контейнера */
.container {
    width: 360px;
    margin: 10px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.currentPlaceButton {
    border: 2px solid #007bff;
    color: black;
    margin: 0px 10px 5px 0px;
    padding: 5px 15px;
    border-radius: 4px;
    background-color: white;
}

.currentPlaceButton:hover{
    background-color: white;
}

/* Стили для кнопки с датой */
.date {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
}

.panelButtons {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.placeButton {
    border: 2px solid #007bff;
    color: white;
    margin: 0px 10px 5px 0px;
    padding: 5px 15px;
    border-radius: 4px;
    background-color: #007bff;
}

/* Стили для блока элемента (название и ввод значения) */
.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

/* Текст элемента */
.item_text {
    font-weight: bold;
    width: 60%;
}

/* Поле для ввода значения элемента */
.item_input_off {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: rgb(0, 184, 0);
}

.item_input_off:hover{
    background-color: rgb(0, 184, 0);
}

.item_input_on:hover{
    background-color: red;
}

.item_input_on {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: red;
}

.rotateOff{
    height: 35px;
    width: 35px;
    transition: 0.5s;
    transform: rotate(0deg);
}

.rotateOn{
    height: 35px;
    width: 55px;
    transition: 0.5s;
    transform: rotate(135deg);
}

/* Стили для кнопки "Сохранить" */
.save_button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

.save_button:disabled {
    background-color: gray;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: default;
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

/* Стили для окна календаря */
.calendarBody {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

