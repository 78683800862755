.contentChart {
    display: flex;
    border-radius: 10px;
    margin-bottom: 60px;
}

.contentChart:last-child{
    margin-bottom: 0px;
}

.content{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    padding: 0px 10px;
}

.selectDate {
    border: 0px;
    background-color: #8884d8;
    border-radius: 4px;
    padding: 8px 12px;
    color: white;
    color-scheme: dark;
}

.psevdoBody {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

.headContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.titleChart {
    font-size: 24px;
}

.dateBox{
    
}

.calendarBody {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 640px) {
    .titleChart{
        font-size: 20px;
    }

    .headContent{
        flex-wrap: wrap;
    }

    .selectDate {
        margin-top: 10px;
        padding: 8px 12px;
        font-size: 14px;
        color: white;
    }

    .contentChart{
        margin-bottom: 40px;
    }

    .content{
        padding-bottom: 10px;
    }
}