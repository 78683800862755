
.bodyMain{
    max-width: 1440px;
    margin: 20px auto;
}

.listItem{
    display: flex;
    justify-content: space-between;
    align-items: center;
}