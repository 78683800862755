.contentBody{
    background-color: white;
    padding: 8px;
    max-width: 1440px;
    width: 100%;
}

.content{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 5px 0px;
}

.contentElement{
    border-radius: 0px 4px 4px 0px;
    border-left: 2.6px solid black;
    padding-left: 5px;
    margin-bottom: 12px;
}

.works{
    display: flex;
    flex-direction: column;
}

.work{
    background-color: #e6ebe7;
    padding: 4px 12px;
    border-radius: 0px 4px 4px 0px;
    margin-top: 5px;
    margin-left: -5px;
}

.work:first-child{
    margin-top: 0px;
}

.goodPoints{
    background-color: #1af52c;
    border-radius: 0px 4px 4px 0px;
    padding: 4px 12px;
}

.warningPoints{
    background-color: #f5f11a;
    border-radius: 0px 4px 4px 0px;
    padding: 4px 12px;
}

.minimumPoints{
    background-color: #f52424;
    border-radius: 0px 4px 4px 0px;
    padding: 4px 12px;
}
