.psevdoBody{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

.content, .contentNoShadow{
    max-width: 1440px;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 18px;
}

.contentNoShadow{
    box-shadow: none;
    transition: 0.3s;
}

.contentNoShadow:hover{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}

.titleContentElement{
    font-size: large;
    font-weight: bold;
    margin-bottom: 4px;
}

.contentElement{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
}

.contentElement:last-child{
    margin-bottom: 0px;
}

.blockInput, .readOnlyInput, .password{
    border: 2.8px solid black;
    border-radius: 4px 0px 0px 4px;
    padding: 8px 12px;
    border-right: 0px;
    width: 100%;
    outline: none;
    transition: 0.3s;
}

.readOnlyInput{
    border-radius: 4px;
}

.blockInput:disabled, .readOnlyInput:disabled{
    background-color: #f0eded;
    border: 2.8px solid #888;
    border-right: 0px;
    transition: 0.3s;
}

.readOnlyInput:disabled{
    border-right: 2.8px solid #888;
}

.blockInput:focus{
    outline: none;
    border: 2.8px solid black;
    border-radius: 4px 0px 0px 4px;
    transition: 0.3s;
}

.boxEdititonal{
    display: flex;
    width: 100%;
}

.editIcon, .saveIcon, .cancelIcon{
    color:  white;
    background-color: rgb(25, 172, 55);
    border: 2.8px solid  rgb(35, 134, 54);
    border-radius: 0px 4px 4px 0px;
    border-left: 0px;
    padding: 5px;
    height: 48px;
    width: 48px;
    cursor: pointer;
}

.linkConnect{
    border: none;
    background-color: green;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    max-width: 120px;
    text-align: center;
    text-decoration: none;
}

.linkConnect:hover{
    color: white;
}

.saveIcon{
    border-radius: 0px;
    border-right: 0px;
}

.cancelIcon{
    background-color: rgb(206, 29, 29);
    border-color: red;
    color: white;
}

.load{
    position: fixed;
    top: 47%;
}

.password{
    border-right: 2.8px solid black;
    border-radius: 4px;
}

.password:focus{
    outline: none;
}

.savePassword{
    background-color: green;
    border: 2.8px solid green;
    color: white;
    border-radius: 4px;
    padding: 8px 12px;
}

.savePassword:hover{
    background-color: white;
    color: black;
}

.savePassword:disabled{
    background-color: gray;
    color: white;
    border-color: gray;
}