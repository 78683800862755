.currentbody {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    position: fixed;
    top: 40%;
    left: 10px;
    right: 10px;
}

.Title{
    margin: 5px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.Author{
    font-size: 0.9em;
    display: flex;
    justify-content: center;
    text-align: center;
    font-style: italic;
}

