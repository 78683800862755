.panelMove{
    background-color: #f9f9f9;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 5px 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dateString{
    color:black;
    font-size: 24px;
}

.dateInput{
    padding: 5px;
    border-radius: 4px;
    border: #8884d8 1.8px solid;
}

.psevdoBody {
    font-family: Arial, sans-serif;
    margin: 20px auto;
    padding: 0;
    max-width: 600px;
    display: flex;
    flex-direction: column;
}

.shift_container {
    background-color: #ffffff;
    margin: 15px 0px;
}

.shift{
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    background-color: #aaa8d8;
    border: 0.5px solid transparent;
    transition: 0.5s;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.field{
    margin-top: 10px;
    color: white;
    font-size: larger;
}

.buttonClose{
    border: 1.4px solid red;
    transition: 0.2s;
    border-radius: 4px;
    background-color: red;
    color: white;
    padding: 8px 12px;
}

.buttonClose:hover{
    transition: 0.2s;
    border: 1.4px solid red;
    border-radius: 4px;
    background-color: white;
    color: red;
    padding: 8px 12px;
}

.emptyShift{
    border: 1.2px solid #8884d8;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}