.main_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
}

.charts{
    margin-top: 12px;
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.content{
    margin-top: 20px;
    max-width: 1440px;
    width: 100%;

    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.title{
    margin: 8px;
}

.data{
    margin: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.contentItem{
    margin: 8px;
    box-shadow: inset 2px 2px 5px rgba(154, 147, 140, 0.5), 1px 1px 5px rgba(255, 255, 255, 1);
    border-radius: 5px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contentItemBody{
    text-align: center;
}

.contentTitle{
    font-size: larger;
}

.chart{
    height: 320px;
}

.updateColor{
    margin-top: 10px;
    color: gray;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.buttons{
    width: 100%;
    display: flex;
    justify-content: space-around;
    border-radius: 4px;
    flex-wrap: wrap;
}

.button, .buttonDisActive{
    border: none;
    padding: 8px 12px;
    background-color: #fff;
    min-width: 300px;;
    transition: 0.4s;
    margin: 4px;
}

.buttonDisActive{
    border-radius: 4px;
    transition: 0.4s;
    box-shadow: 0 0 5px rgb(160, 136, 136);
}