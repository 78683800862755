.psevdoBody{
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content{
    max-width: 1440px;
    width: 100%;
}

.feedbacks{
    display: flex;
    flex-direction: column;
}

.emptyFeedbacks{
    border-radius: 4px;
    background-color: rgb(203, 175, 230);
    padding: 18px 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: larger;
    font-weight: bold;
}

.feedback{
    background-color: rgb(203, 175, 230);
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 8px 12px;
    transition: 0.3s;
}

.feedback:hover{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    margin: 12px 12px 22px 12px;
}

.feedback:last-child{
    margin-bottom: 0px;
}

.title{
    font-size: large;
    font-weight: bold;
    margin-right: 4px;
}

.author{
    font-style: italic;
}

.headFeedback{
    display: flex;
    justify-content:  start;
    align-items: center;
    flex-wrap: wrap;
}

.message{
    margin-top: 5px;
    font-size: medium;
}

.formFeedback{
    background-color:rgb(203, 175, 230);
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 16px;
    transition: 0.3s;
}

.formFeedback:hover{
    transition: 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.inputModel{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.titleModel{
    font-weight: bold;
}

.inputTitle, .inputMessage{
    outline: none;
    border-color: transparent;
    padding: 8px 12px;
    border-radius: 4px;
}

.inputMessage{
    min-height: 80px;
    font-size: small;
}

.send{
    padding: 8px 12px;
    border-radius: 4px;
    background-color: white;
    border: none;
}

.filters{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
}

.filter{
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid rgb(203, 175, 230);
    padding: 8px 12px;
    margin-right: 6px;
    margin-top: 6px;
    transition: 0.3s;
}

.filterUse, .filter:hover, .filterUse:hover{
    transition: 0.3s;
    background-color: rgb(203, 175, 230);
}

.filterUse{
    border-radius: 4px;
    border: 1px solid rgb(203, 175, 230);
    padding: 8px 12px;
    margin-right: 6px;
}

.filter:last-child{
    margin-right: 0px;
}

