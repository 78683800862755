.main {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

.body_load{
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: 50%;
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    padding: 0px 10px;
}

.title {
    font-size: 24px;
}

.rechart {
    min-width: 350px;
    max-height: 250px;
}

.elements {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.rechartContent {
    display: flex;
    flex-wrap: wrap;
    width: 972px;
}

.textContent {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.titleItem {
    font-size: 18px;
}

.itemRechart {
    width: 210px;
    margin-right: 276px;
    margin-top: 20px;
}

.item {

}

.labelTitle{
    font-size: 18px;
    margin-bottom: 8px;
}

.labelTitle:last-of-type{
    margin: 0px;
}

.footerText{
    border-top: 1px solid rgb(214, 214, 214);
    padding-top: 20px;
    margin-top: 20px;
    font-size: 18px;
    margin-bottom: 20px;
}


@media (max-width: 640px) {

    .itemRechart{
        margin-right: 0;
        width: 100%;
    }
}