/* Основные стили для контейнера */
.container {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .content{
    width: 100%;
    max-width: 2000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .currentPlaceButton {
    border: 2px solid #007bff;
    color: black;
    margin: 0px 10px 5px 0px;
    padding: 5px 15px;
    border-radius: 4px;
    background-color: white;
  }
  
  .currentPlaceButton:hover {
    background-color: white;
  }
  
  /* Стили для кнопки с датой */
  .date {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
  }
  
  .panelButtons {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .placeButton {
    border: 2px solid #007bff;
    color: white;
    margin: 0px 10px 5px 0px;
    padding: 5px 15px;
    border-radius: 4px;
    background-color: #007bff;
  }
  
  /* Стили для блока элемента (название и ввод значения) */
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    max-width: 320px;
    width: 100%;
  
    border-bottom: 1.1px solid #ccc;
    padding: 4px;
  }
  
  /* Текст элемента */
  .item_text {
    font-weight: bold;
    width: 60%;
  }
  
  /* Поле для ввода значения элемента */
  .item_input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 30%;
  }
  
  /* Стили для кнопки "Сохранить" */
  .save_button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    width: 100%;
  }
  
  .save_button:disabled{
    background-color: white;
    color: black;
    cursor: progress;
  
    transition: 0.5s;
  }
  
  .productsSend {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
  }
  
  .categoryItem{
    width: 340px; 
    min-width: 200px; 
    margin-bottom: 15px;
    box-sizing: border-box;
    padding: 0 10px;
  }
  
  .categorySends{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
  
  .titleCategory{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #007bff;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    margin-bottom: 6px;
  }
  
  /* Стили для окна календаря */
  .calendarBody {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }