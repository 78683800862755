.psevdoBody{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
}

.machineHead{
display: flex;
align-items: center;
}

.machineCheckLive{
    width: 18px;
    height: 18px;
    margin: 4px
}

.content{
    display: flex;
    flex-direction: column;

    margin-top: 20px;
    max-width: 1440px;
    width: 100%;
    padding-top: 10px;

    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.contentItem{
    margin-bottom: 18px;
}

.contentItem:last-child{
    margin-bottom: 0px;
}

.contentTitle{
    font-size: larger;
    font-weight: 600;
    margin-left: 10px;
}

.contentChild{
    margin-top: 5px;
}

.contentAnalyze{
    transition: 0.4s;
}

.selectDate{
    max-width: 350px;
    margin: 10px;
}

.filters{
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 5px;
}

.filter, .filterActive{
    width: 33%;
    border: 1px solid #8884d8;
    border-radius: 4px;
    padding: 4px 0px;
    height: 36px;
    transition: 0.4s;
}

.filterActive{
    border: 0px solid transparent;
    color: white;
}

.buttonMachines{
display: flex;
justify-content: space-around;
flex-wrap: wrap;
}

.buttonMachine, .buttonMachineActive{
    padding: 8px 12px;
    border-radius: 4px;
    background-color: white;
    border: 1px solid #8884d8;
    transition: 0.4s;
    margin: 0px 4px 4px 0px;
}

.buttonMachineActive{
    border: 1px solid transparent;
}

.moveState{
    border: none;
    margin: 6px 0px;
    background-color: #8884d8;
    border-radius: 4px;
    color: white;
    padding: 8px 12px;
}

.chartStatistic{
    display: flex;
}

.contentAnalyze{
    margin: 12px;
}

.contentLasers{
    border-top: 1px solid gray;
}