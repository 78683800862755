.main {
    margin: 10px;
    font-size: small;
}

.typeProductCategory {
    margin-left: 6px;
    margin-right: 6px;
}

.typeColor {
    width: 24px;
    border-radius: 6px;
    height: calc(1.375rem + 1.5vw);
}

.tabItemInCheck{
    margin-bottom: 30px;
}

.productCategory {
    margin-bottom: 0px;
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.productTitle {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.productItem{
    width: 100%;
}

.bodyCategories {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.bodyAdditions {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    padding: 8px 0px;
}

.productForm {
    margin-bottom: 2px;
}

.container_category{
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 16px;
}

.additionItem {
    width: max-content;
}

.additionBodyProduct{
    display: flex;
    flex-wrap: wrap;
    padding-right: 8px;
}

.buttonsAction{
    display: flex;
    margin-top: 20px;
}

.buttonSave, .buttonImport{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttonSave{
    margin-right: 6px;
}

.productPriceBlock, .productPriceBlockHidden {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0 4px 0;
    justify-content: space-around;
    border-top: 1px dashed #0d6dfd;
    transition: 1s;

    opacity: 1;
    transition: 0ms 0ms 200ms 0ms;
}

.productPriceBlockHidden{
    overflow: hidden; /* Hide the element content, while height = 0 */
    height: 0;
    opacity: 0;
    padding: 0;
    transition: 0ms 200ms 200ms 0ms;
}

.productPriceItem, .productPriceItemSum{
    width: 100%;
    display: flex;
    align-items: center;
}

.borderTop{
    width: 100%;
    border-top: 1px solid black;
    margin-top: 8px;
}

.productPriceItemSum{
    border-top: 1px solid black;
    margin-top: 8px;
}

.price_name {
    font-weight: bold;
    margin-right: 0px;
    text-align: left;
}

.price_separator {
    flex: 1;
    border-bottom: 2px dotted #999;
    margin: 0.6em 5px 0;
}

.price_value {
    font-weight: bold;
    text-align: right;
}

.bodyAdditionInOrder{
    padding: 0px 14px;
}