.panelMove{
    background-color: #f9f9f9;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 5px 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dateString{
    color:black;
    font-size: 24px;
}

.dateInput, .dateInputShift{
    padding: 5px;
    border-radius: 4px;
    border: #8884d8 1.8px solid;
}

.historyBody {
    font-family: Arial, sans-serif;
    margin: 20px auto;
    padding: 0;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
}

.shift_container {
    margin: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.content{
    max-width: 1440px;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    flex-wrap: nowrap;
}

.example_enter {
    opacity: 0.01;
}

.example_enter.example_enter_active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.example_exit {
    opacity: 1;
}

.example_exit.example_exit_active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.shiftModeEdit{
    margin: 15px 0px 15px 15px;
    max-width: 440px;
}

@media (max-width: 700px) {
    .shiftModeEdit{
        background-color: white;
        padding: 12px;
        max-width: max-content;
        margin: 0;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.extraWorkPullModeEdit{
    width: 100%;
    margin-top: 15px;
}
.extraWorkPullProperties{
    display: flex;
    flex-direction: column;
    margin: 8px 0px;
}
.extraWorkPullProperty{
    display: flex;
    flex-direction: column;
    margin-bottom: 6px;
}

.extraWorkPullProperty:last-child{
    margin-bottom: 0px;
}

.buttonSaveActionExtraWorkPull, .buttonCancelActionExtraWorkPull{
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
}

.buttonSaveActionExtraWorkPull{
    background-color: green;
    margin-right: 6px;
}

.buttonCancelActionExtraWorkPull{
    background-color: red;
}

.extraWorkDelete{
    height: 20px;
    width: 20px;
    color: red;
    margin-left: 6px;
}

.shift{
    max-width: 460px;
    border-radius: 4px;
    margin: 5px;
    background-color: #aaa8d8;
    border: 0.5px solid transparent;
    transition: 0.5s;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.shift_content{
    padding: 10px;
    background-color: transparent;
}

/* Стили для дополнительных работ */
.extra_works {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.extra_work {
    margin-right: 5px;
    margin-top: 5px;
}

.titleDay{
    background-color:  #8884d8;
    padding: 10px;
    color: white;
    border-radius: 0px 0px 4px 0px;
    font-size: 20px;
}

.textInfoShift{
    font-size: 18px;
    margin-left: 10px;
    color:white;
}

.shiftHead{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shiftEdit{
    border: 2.5px solid #8884d8;
    border-radius: 4px;
    padding: 5px;
    margin-right: 15px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    color: black;
    background-color: white;
}

.panelEdit, .panelCreate{
    position: fixed;
    background-color: #ffffffa6;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 0px 8px;
}

.objectOFF{
    display: none;
}

.panelEdit_content, .panelCreate_content{
    background-color: white;
    border: 2.5px solid #8884d8;
    border-radius: 4px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 550px;
    margin: 0px auto;
    margin-top: 50px;
}

.buttonsEdit{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.buttonEditCancel, .buttonEditSave, .buttonEditBack{
    border-radius: 4px;
    font-weight: 700;
    width: 32%;
    border: 2px solid;
    height: 40px;
    transition: 0.2s;
}

.buttonEditBack{
    color:gray;
}

.buttonEditBack:hover{
    background-color: gray;
    color: white;
}

.buttonEditCancel{
    color: red;
}

.buttonEditCancel:hover{
    background-color: red;
    color: white;
}

.buttonEditSave{
    color:green;
}

.buttonEditSave:hover{
    background-color: green;
    color: white;
}


.inputItemEdit{
    padding: 5px;
    margin-top: 10px;
    height: 35px;
}

.nameItemEdit{
    margin-top: 10px;
    font-weight: bold;
    font-size: 18px;
}

.iconEdit{
    height: 24px;
    width: 24px;
}

.plusExtraWork{
    margin-top: 5px;
    padding: 5px;
    border: 1px solid green;
    border-radius: 4px;
    color: white;
    background-color: green;
    height: 35px;
    width: 50px;
    cursor: pointer;
}

.selectWorks{
    height: 32px;
    padding: 5px;
}

.datePanel{
    margin-bottom: 8px;
    background-color: white;
    padding: 8px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.buttonSaveDate{
    margin-top: 15px;
    background-color: white;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    border: 1px solid #8884d8;
}

.buttonSaveDate:disabled{
    background-color: rgb(185, 185, 185);
    color: grey;
    cursor: default;
}

.emptyShift{
    border: 1.2px solid #8884d8;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.dayOffContainer{
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.dayOffCheckBox{
    width: 18px;
    height: 18px;
}