.name_code_container {
    margin: 20px auto;
    text-align: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.customH1 {
    font-size: 24px;
    margin-bottom: 10px;
}

.customP {
    font-size: 18px;
}

/* Стили для имени и кода */
.name {
    font-weight: bold;
    color: #007bff;
    background-color: #f2f2f2;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.statusClose {
    font-weight: bold;
    color: red;
    background-color: #f2f2f2;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.statusOpen {
    font-weight: bold;
    color: #007b00;
    background-color: #f2f2f2;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.code {
    font-weight: bold;
    background-color: #f2f2f2;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Стилизация progress bar */
.progressBar {
    width: 100%;
    height: 20px;
    background-color: #f2f2f2;
    border-radius: 10px;
    margin: 10px 0;
  }
  
  .progressBar::-webkit-progress-value {
    background-color: #007bff; /* Цвет для индикации прогресса */
    border-radius: 10px;
    transition: width 0.5s; /* Анимация изменения ширины */
  }
  
  .progressBar::-webkit-progress-bar {
    background-color: #f2f2f2;
    border-radius: 10px;
  }
  
  /* Убираем стандартный стиль для Firefox */
  .progressBar::-moz-progress-bar {
    background-color: #007bff;
    border-radius: 10px;
  }
  