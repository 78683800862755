.psevdoBody{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.content{
    display: flex;
    flex-direction: column;

    max-width: 1440px;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.contentItem{
    margin-bottom: 10px;
    padding: 4px;
    box-shadow:  2px 2px 5px rgba(154, 147, 140, 0.5), 1px 1px 5px rgba(255, 255, 255, 1);
    border-radius: 4px;
}

.contentItem:last-child{
    margin-bottom: 0px;
}

.checkPoints{
    display: flex;
    flex-direction: column;
}

.checkItem{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.checkItem:last-child{
    margin-bottom: 0px;
}

.checkTitle{
    font-size: large;
    user-select: none;
    margin-left: 5px;
}

.checkBody{
    width: 18px;
    height: 18px;
    outline: none;
}

.workerButtons{
    margin: 5px 0px;
}

.buttonAll, .buttonClear, .buttonAll:hover, .buttonClear:hover{
    border-radius: 4px;
    border: 1px solid transparent;
    background-color:  rgb(203, 175, 230);
    margin-right: 5px;
}


.contentItems{
    display: flex;
    flex-direction: column;

    width: 100%;
}

.workerCount{
    margin-top: 10px;
}

.download, .download:hover, .downloadFixed{
    border-radius: 4px;
    border: 1px solid transparent;
    background-color:  #008000;
    margin-top: 15px;
    color: white;
    padding: 8px 12px;
    width: 100%;

    transition: 0.5s;
    
    transition-duration: 2s;
}

.downloadFixed{
    position: fixed;
    width: 100px;
    bottom: 30px;
    right: 30px;

    max-width: 1400px;
    transition: 0.5s;
    transition-duration: 2s;
}

.downloadFixed:disabled, .download:disabled{
    border: 1px solid gray;
    background-color:  white;
    color: black;
}

.block{
    height: 60px;
}

.buttonShow{
    width: 100%;
    border-radius: 4px;
    border: 0px solid transparent;
    padding: 8px 12px;
    background-color: #386991;
    color: white;
}

@media (min-width: 750px){

    .contentItems{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .contentItem{
        padding: 10px;
        width: 20%;
        margin-right: 4px;
    }

}