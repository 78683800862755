.container_main {
    
    margin: 20px;
    display:flex;
    flex-direction:column;

    text-align: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.customH1 {
    font-size: 24px;
    color: #007bff;
    margin-bottom: 20px;
}

.codeInput {
    padding: 10px;
    text-align: center;
    font-size: 18px;
    border: 2px solid #007bff;
    border-radius: 5px;
    outline: none;
}

    .codeInput::placeholder {
        color: #ccc;
    }

.submitBtn {
    margin-top: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

    .submitBtn:hover {
        background-color: #0056b3;
    }