.load {
    position: absolute;
    top: 40%;
    right: 40px;
    left: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav_link_toggle, .nav_link_top{
    border-radius: 4px;
    color: black;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0.5rem 16px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.nav_link_toggle{
    padding: 0.5rem 8px;
}

.nav_link_toggle:hover, .nav_link_toggle:active, .nav_link_top:hover, .nav_link_top:active{
    background-color: #8884d830;
    color: #8884d8;
}

.nav_link_text{
    font-size: 18px;
}

.nav_link_icon{
    width: 20px;
    height: 20px;
    margin-right: 8px;
}