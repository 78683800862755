.main {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

.content{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    padding: 0px 10px;
}

.elements {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin-top: 8px;
}

.element {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: start;
    background-color: white;
    border: 1.2px solid transparent;
    border-radius: 8px;
    margin: 0px 0px 0px 8px;
    padding: 8px 12px;
}

.currentDateText{
    padding-left: 8px;
    font-size: 18px;
    margin-top: 20px;

}

.headContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.titleChart {
    font-size: 24px;
}

.elementText{
    margin: 0 0 0 8px;
    text-wrap: nowrap;
}

.categoryItem{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: start;
    margin-bottom: 20px;
    margin-right: 6px;
}

.categoryName{
    padding: 8px 12px;
    text-align: start;
    font-size: 12px;
    color: grey;
    margin-left: 8px;
    margin-bottom: 6px;
    border-radius: 4px;
}

.elementIn{
    background-color: white;
    border: 1.2px solid transparent;
    border-radius: 8px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.dateBox{
    margin: 0px 8px 8px 8px;
}

.dateBox:last-child{
    margin: 0px;
}

.clear {
    background-color: white;
    border: 1.2px solid red;
    border-radius: 8px;
    max-width: 320px;
    align-self: center;
    padding: 8px 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dateMiddleText{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    background-color: rgb(13, 110, 253);
    color: white;
}


@media (max-width: 640px) {
    .titleChart{
        font-size: 20px;
        margin-bottom: 10px;
    }

    .categoryName{
        margin-left: 4px;
    }

    .headContent{
        flex-wrap: wrap;
    }

    .contentChart{
        margin-bottom: 40px;
    }

    .element{
        margin: 0px 0px 0px 4px;
    }

    .content{
        padding-bottom: 10px;
    }
}