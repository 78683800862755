.container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.buttonsUpper{
    margin-bottom: 18px;
}

.content{
    max-width: 1440px;
    width: 100%;
}

.accordionHeader{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 5px;
}

.categories{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.buttonsEvent{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.badge{
    margin-right: 5px;
}

.buttonCategory{
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 4px 12px;
}

.buttonEvent{
    width: 33%;
}

.stateMonth{
    font-size: 12px;
    color: #5394fd;
    margin-bottom: 10px;
}

.monthBlock{
    margin-bottom: 40px;
}


.elements{
    display: flex;
    justify-content:space-between;
    flex-wrap: wrap;
}


@media (max-width: 640px) {
    
}