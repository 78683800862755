.beta_body{
    display: flex;
    justify-content: center;
}

.menu {
    width: 600px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 20px 20px 20px 20px;
    margin: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Стили для кнопки начала смены */
.buttonStart {
    background-color: #007bff;
    color: white;
    border: none;
    margin: 30px 0px 0px 0px;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.titleLabel{
    margin: 20px 0px 0px 0px;
    font-size: x-large;
}

.buttonStart:hover {
    background-color: #0056b3;
}

/* Стили для элементов машины */
.machine_item {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.machine_item_disable{
    color: gray;
}

.fieldData {
    margin: 10px 0px 10px 0px;
}

/* Стили для чекбокса */
.ceckBoxInput {
    margin: 10px 10px 10px 5px;
    transform: scale(1.5);
}