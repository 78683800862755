/* Стили для контейнера .main_body */
.main_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  /* Стили для заголовка .title */
  .title {
    font-size: 24px;
    color: #333;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Стили для .content_container */
  .content_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Стили для .input */
  .input {
    width: 100%;
    padding: 10px;
    margin: 10px 0px 0px 0px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  
  /* Стили для кнопки .button_login */
  .button_login {
    background-color: #8884d8;
    color: #fff;
    padding: 10px 20px;
    border: 1px solid transparent;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .button_login:hover {
    background-color: #8884d830;
    color: #8884d8;
    border: 1px solid #8884d8;
  }
  
  /* Стили для .move_person */
  .move_person {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    text-align: center;
    width: 100%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }

  .optionItem{
    background-color: white;
    color: black;
  }
  
  .move_person:hover {
    background-color: #0056b3;
  }
  
  /* Стили для .switch_person */
  .switch_person {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  /* Стили для .select_employee */
  .select_employee {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px 0;
  }
  
  .select_employee:hover {
    background-color: #0056b3;
  }
  
  /* Стили для .select_manager */
  .select_manager {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px 0;
  }
  
  .select_manager:hover {
    background-color: #0056b3;
  }
  